<template lang="html">
  <a
    class="nhs-back-btn"
    :href="href"
    @click="handleClick"
  >
    <Icon
      aria-hidden="true"
      class="me-2"
      name="chevronLeftSm"
      :size="15"
    />Back
  </a>
</template>

<script>
export default {
  props: {
    buttonPath: { type: String, required: false, default: '' },
    handler: { type: Function, required: false, default: undefined },
  },

  computed: {
    href() {
      return this.buttonPath || '#';
    },
  },

  methods: {
    handleClick(e) {
      if (typeof this.handler === 'function') {
        e.preventDefault();
        this.handler();
      }
    },
  },
};
</script>
